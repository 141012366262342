import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'

import createCache from 'vuex-cache';

import buyers from './modules/buyers'
import listings from './modules/listings'
import properties from './modules/properties'
import config from './modules/config'
import leads from './modules/leads'
import user from './modules/user'
import growthbook from './modules/growthbook'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    buyers,
    listings,
    properties,
    config,
    leads,
    user,
    growthbook
  },
  plugins: [
    createCache(),
  ],
  state: {
    pageType: '', //like "bma", "seller-activity-report" etc..

      // "mode" here is NOT the same as the visual theme info in SASS/CSS/Config API.
      // "mode" is used to distinguish what kind of
      // webpage we are looking at (is it a Berkshire page? a generic one?).
      // It must be overriden in the base component of every main page
      // in the /pages directory.
      // (see /pages/berkshire/HomeEquityBH.vue for example)
    mode: 'generic', // === either "berkshire" or "generic"

    // This determines whether the app-wide Buyside Spinner is shown or hidden
    spinner: false,
    failure: false
  },
  getters: {
    pageType: default_getter('pageType'),
    mode (state) {
      return state.mode || 'generic' //generic is default
    }
  },
  mutations: {
    setPageType: default_setter('pageType'),
    setMode:     default_setter('mode'),
    setSpinner:  default_setter('spinner'),
    setFailure:  default_setter('failure'),
  },
  actions: {
    resetModules ({ commit }) {
      commit('buyers/_resetState')
      commit('leads/_resetState')
      commit('listings/_resetState')
      commit('properties/_resetState')
      // We do NOT reset the user module within session by default
    },
    postMessageToAgent ({state}, payload) {
      if(!state.leads.leadID) {
        // We don't have a Lead ID for some reason, although we should.
        throw "No lead ID present for postMessageToAgent"
      }
      return api.postMessageToAgent(state.leads.leadID, payload)
    },
    postMessageToAgentSAGE (context, payload) {
      return api.postMessageToAgentSAGE(payload)
    },
    post_v1_MyEquityLead ({state}, payload) {

      payload.lat = payload.lat || state.listings.lat
      payload.lng = payload.lng || state.listings.lng
      payload.beds = payload.beds || state.listings.beds
      payload.baths = payload.baths || state.listings.baths

      return api.post_v1_MyEquityLead(payload)
    },
    post_v1_MyEquityLead_shortForm (context, payload) {
      return api.post_v1_MyEquityLead(payload, true)
    }
  }
})



///////////////////////
// UTILITY FUNCTIONS //
///////////////////////

export { copyIntoRecursive } from '@/util'

/*
  Generates a default setter of the form:
    
    variable(state, value) {
      state.variable = value;
    }

  Reduces boilerplate code!
*/
export function default_setter (key) { return (state, value) => state[key] = value; }

/*
  Generates a default getter of the form:
    
    variable(state) {
     return state.variable;
    }

  ** REALLY, you shouldn't need this. 
  Better to use mapState(['variable']) instead of mapGetters(['variable'])
  for trivial getters. mapGetters is only really useful for non-trivial computation
*/
export function default_getter (key) { return (state) => state[key] }