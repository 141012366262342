import api from '@/api'
import router from '@/router'
import {copyIntoRecursive,
        default_getter as get,
        default_setter as set} from '@/store'

import moment from 'moment'
import {formatPrice, normalizePropertyType} from '@/filters'

const getInitialState = function () {
  return {
    address: {
      address1: "",
      address2: null,
      city: "",
      state: "",
      zipcode: "",
      county: "",
      brokerage_id: ""
    },
    lat: null,
    lng: null,
    altLat: null, // This is used for situations where we use lat/long from alt sources (like the Page 1 autocomplete)
    altLng: null,
    beds: null,
    baths: null,
    baths_full: null,
    baths_half: null,
    finished_area: null,
    finished_area_units: null,
    full_street_address: null,
    last_sold_value: null,
    last_sold_date: null,
    listing_date: null,
    listing_status: null,
    list_price: 0,
    lot_size: null,
    living_area: null,
    living_area_range: null,
    estimate: null,
    estimate_low: null,
    estimate_high: null,
    property_type: null,
    year_built: null,
    basement: null,
    building_floors: null,
    comments: null,
    fireplaces: null,
    stories: null,
    township: null,
    pool: null,
    brokerage_name: null,
    lookup_provider_name: null,

    active_listing: null, //will be an object mimicking the root store structure, nested 1 level deep

    // Market Activity listings:
    agentDefinedMAFilters: null,
    agentDefinedMAFiltersFromSAR: null,

    listings: [],
    activeListings: [],
    soldListings: [],
    pendingListings: [],

    MarketActivityPolygonData: [],
    polygonEnabledOnMap: false,

    showingFeedbackData: [],
    showingAgentFeedbackData: [],

    openHouseData: [],
    openHouseDataSAGE: {},
    recentShowings: [],
    vendorViews: [],
    vendorAvgViews: [],
    consolidatedViews: [],
    vendorViewsGroupedByVendor: [],
    vendorShares: [],
    vendorSharesGroupedByVendor: [],
    vendorInquiries: [],
    vendorInquiriesGroupedByVendor: [],

    //Website Activities
    sharedProperties: [],
    savedSearch: [],
    savedProperties: [],
    askQuestion: [],
    showingRequest: [],
    websiteOffer: [],

    avgSharedProperties: [],
    avgSavedSearch: [],
    avgSavedProperties: [],
    avgAskQuestion: [],
    avgShowingRequest: [],
    avgWebsiteOffer: [],
    
    propertyImage: '',
    photos: [],
    listingMarketingTips: [],

    // used for Heatmap filtering
    listingPropertyTypes: [],
    cityZips: [],

    // Used in My Equity components
    // These are sourced from "corelogic" in Buyside API and should exclusively be used for equity calculations.
    mortgageData: {
      loanStartDate:        null, //start_date
      loanInitialValue:     null, //original_balance
      loanLengthInYears:    null, //term
      loanInterestRate:     null, //mortgage_rate
      homeValueEstimate:    null, //value
      netWorth:             null, //net_worth,         == (value - remaining_balance)
      loanRemainingBalance: null, //remaining_balance, == remaining principal to be paid off as of "now"
      lastSoldPrice:        null, //last_sold_amount
      mortgageDetails:        null, //mortgage_details

      loanStartDate__original       : null,
      loanInitialValue__original    : null,
      loanLengthInYears__original   : null,
      loanInterestRate__original    : null,
      loanRemainingBalance__original: null
    },

    refinanceRates: {
      thirtyYearFixed: null,
      fifteenYearFixed: null
    },

    // Page 2 state information
    isClaimed: false, // Whether this property has been claimed by the user
    isSubscribed: false,

    /* Loading Area */
    loadingOpenHouse: true,
    loadingShowingFeedbackData: true,
    loadingShowingAgentFeedbackData: true,
    loadingRecentShowings: true,
    loadingVendorInquiries: true,
    loadingVendorShares: true,
    loadingVendorViews: true,
    loadingListing: true,
    loadingListingByID: true,
    loadingListingMarketingTips: true,
    loadingListings: true,
    loadingActiveListings: true,
    loadingSoldListings: true,
    loadingPendingListings: true,
    loadingMarketActivityPolygonData: true,
    loadingMortgageData: true,
    loadingRefinanceRates: true,

    //Website Activities
    loadingSavedSearch: true,
    loadingSavedProperties: true,
    loadingSharedProperties: true,
    loadingAskQuestion: true,
    loadingShowingRequest: true,
    loadingWebsiteOffer: true,
  }
};

const normalizeParam = function( inputVal, paramName, mortgageDataBoundsObj ) {

  if(inputVal && !mortgageDataBoundsObj.isValidFn(paramName)(inputVal))
  {
    // eslint-disable-next-line
    console.warn("My Equity mortgage data: `" + paramName + "`'s value [" + inputVal + "] is out of bounds and was set to a default min/max.")
  }

  if(inputVal && Object.getPrototypeOf(inputVal).constructor.name != mortgageDataBoundsObj[paramName].type) //relies on ES2015 object coercion
  {
    // eslint-disable-next-line
    console.warn("My Equity mortgage data: `" + paramName + "` is of unexpected type " +
      (inputVal ? Object.getPrototypeOf(inputVal).constructor.name : ''+inputVal));
    return null;
  }

  if([null, undefined].includes(inputVal)) // but let numeric 0 go through
    return null;
  else if(inputVal < mortgageDataBoundsObj[paramName].min)
    return mortgageDataBoundsObj[paramName].min;
  else if(inputVal > mortgageDataBoundsObj[paramName].max)
    return mortgageDataBoundsObj[paramName].max;
  else return inputVal;
};

const mortgageDataBoundsFn = (state) => Object.freeze({
  loanStartDate: Object.freeze({
    type: "Date",
    min: new Date(1950, 0, 1),
    max: new Date(),
    default: new Date(new Date() - (1000 * 60 * 60 * 24 * 365)), //approx 1 year ago,
    epsilon: 1000 * 60 * 60 * 24 // one day (really should be near 1 month but months have variable # of days)
  }),
  loanInitialValue: Object.freeze({
    type: "Number",
    min: 500,
    max: 99999999,
    default: 400000,
    epsilon: 0.9
  }),
  loanLengthInYears: Object.freeze({
    type: "Number",
    min: 1,
    max: 50,
    default: 30,
    epsilon: 0.9
  }),
  loanInterestRate: Object.freeze({
    type: "Number",
    min: 0.001,
    max: 50,
    default: 3,
    epsilon: 0.0009
  }),
  loanRemainingBalance: Object.freeze({
    type: "Number",
    min: 0,
    max: state.mortgageData.loanInitialValue,
    default: 400000,
    epsilon: 0.9
  }),
  // homeValueEstimate: Object.freeze({
  //   type: "Number",
  //   min: 1,
  //   max: 99999999,
  //   default: 500000
  // }),
  isValidFn (param) {
    let _this = this; //== this obj, mortgageDataBounds
    return function (value) {
      return value && value >= _this[param].min && value <= _this[param].max;
    }
  }
});

const by_vendor_getter = stateProperty => state => {
  let obj = {
    first_week: null,
    data: []
  }
  if (!(state[stateProperty] && Array.isArray(state[stateProperty]) && state[stateProperty].length > 0))
    return obj

  obj.first_week = state[stateProperty][0].date
  obj.data = state[stateProperty]

  return obj
};

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    address (state) {
      if(state.full_street_address !== null) {
        return state.full_street_address
      }
      if (state.address.address1 !== null) {
        return state.address.address1
      }
      return "--"
    },
    city (state) {
      return state.address.city
    },
    county (state) {
      return state.address.county
    },
    listingState (state) {
      return state.address.state
    },
    zipcode (state) {
      return state.address.zipcode
    },
    cityStateZip (state) {
      let csz = ""
      csz += state.address.city
      if (state.address.city && state.address.state) csz += ", "
      csz += state.address.state
      if (state.address.state && state.address.zipcode) csz += " "
      csz += state.address.zipcode

      return csz
    },
    fullAddress (state, getters) {
      let address = ""
      if(state.address.address1 != "") {
        address += state.address.address1 + " "
        address += (state.address.address2) ? state.address.address2 + " ": ""
        address += getters.cityStateZip
      }
      return address
    },
    beds (state) {
      if (state.beds !== null) {
        return state.beds
      }
      return "--"
    },
    baths (state) {
      if (state.baths !== null) {
        return state.baths
      }
      return "--"
    },
    finishedArea (state) {
      if (state.finished_area !== null) {
        return state.finished_area
/*
        if (state.finished_area_units !== null) {
          string+= state.finished_area_units
        }
*/
      }
      return 0
    },
    livableSqFtAsNumber (state, getters, rootState) {
      if (['marketActivityReport','homeEquityReport','bma'].includes(rootState.pageType))
      {
        return Number(getters.finishedArea)
      }
      else if (rootState.pageType === 'sellerActivityReport')
      {
        if(Array.isArray(state.living_area_range) && state.living_area_range.length >= 1) {
          // We have a range of square feet, so show it as a range.
          const [min] = state.living_area_range;
          return Number(min)
        }
        else if (state.living_area) {
          return Number(state.living_area)
        }
        else if (state.lot_size) {
          return Number(state.lot_size)
        }
        else return NaN
      }
      else return NaN
    },
    lastSoldValue (state) {
      if (state.last_sold_value !== null) {
        return state.last_sold_value
      }
      return '--'
    },
    lastSoldDate (state) {
      if (state.last_sold_date !== null) {
        return state.last_sold_date
      }
      return 'N/A'
    },
    listedDate (state) {
      if (state.listing_date) {
        return state.listing_date
      }
      return "--"
    },
    listPrice (state) {
      return state.list_price
    },
    lotSize (state)  {
      if (state.lot_size !== null) {
        return state.lot_size + " "
      }
      return '--'
    },
    propertyType (state) {
      if (state.property_type !== null) {
        return state.property_type
      }
      return "--"
    },
    galleryPhotos (state) {
      var photos = []
      state.photos.forEach(function(photo) {
        photos.push({
          src: photo,
          thumbnail: photo
        })
      })
      return photos
    },
    features (state) {

      const features = [
        {label: "Property Type", value: state.property_type},
        {label: "Bedrooms", value: state.beds},
        {label: "Bathrooms", value: state.baths},
        {label: "Year Built", value: state.year_built},
        {label: "Basement", value: state.basement},
        {label: "Number of Floors", value: state.building_floors},
        {label: "Fireplaces", value: state.fireplaces},
        {label: "Stories", value: state.stories},
        {label: "Pools", value: state.pool},
      ];

      return features.filter( item => item.value != null)
    },
    redirectURL (state) {
      if(state.active_listing && state.active_listing.company_exclusive_url)
        return state.active_listing.company_exclusive_url;
      else return '';
    },
    listingStatus(state) {
      switch (String(state.listing_status).toLowerCase()) {
        case 'active':
        case 'active - contingent':
        case 'active - under contract':
        case 'for rent':
        case 'quiet':
          return "Active";
        case 'comingsoon':
          return "Coming Soon";
        case 'pending':
        case 'contingent':
          return "Pending";
        case 'sold':
        case 'sale':
        case 'closed':
          return "Sold";
        case 'inactive':
        case 'cancelled':
        case 'withdrawn':
        case 'off market':
        case 'expired':
          return "Off Market";
        case 'null':
        case 'undefined':
        default:
          return '';
      }
    },
    comments:                   get('comments'),
    brokerageName:              get('brokerage_name'),
    lat:                        get('lat'),
    lng:                        get('lng'),
    baths_half:                 get('baths_half'),
    baths_full:                 get('baths_full'),
    estimate:                   get('estimate'),
    listings:                   get('listings'),
    activeListings:             get('activeListings'),
    soldListings:               get('soldListings'),
    pendingListings:            get('pendingListings'),
    hasMarketActivityPolygonData(state) {
      return state.MarketActivityPolygonData?.coordinates?.length > 0
    },
    showingFeedbackData:        get('showingFeedbackData'),
    showingAgentFeedbackData:   get('showingAgentFeedbackData'),
    recentShowingsArray (state) {
      var showingCounts = []
      state.recentShowings.forEach(function(week) {
        showingCounts.push(week.showings_count)
      })
      return showingCounts
    },
    vendorViewsAsArray (state) {
      var vendorViews = []
      state.vendorViews.forEach(function(date) {
        vendorViews.push(date.count)
      })
      return vendorViews
    },
    consolidatedViewsArray (state) {
      var consolidatedViews_data = []

      state.consolidatedViews.forEach(function(date) {
        consolidatedViews_data.push(date.count)
      })
      return consolidatedViews_data

    },
    averageViewsArray (state) {
      var averageViews = []
      state.vendorAvgViews.forEach(function(date) {
        averageViews.push(date.count)
      })
      return averageViews
    },

    averageSavedSearch(state){
      var savedSearchArray = []
      
      state.avgSavedSearch.forEach(function(date){
        savedSearchArray.push(date.count)
      })

      return savedSearchArray;
    },

    averageSavedProperties (state){
      var savedPropertiesArray = []

      state.avgSavedProperties.forEach(function(date){
        savedPropertiesArray.push(date.count)
      })

      return savedPropertiesArray;
    },

    averageSharedProperties (state){
      var sharedPropertiesArray = []
      
      state.avgSharedProperties.forEach(function(date){
        sharedPropertiesArray.push(date.count)
      })
      
      return sharedPropertiesArray;
    },

    averageAskQuestion (state){
      var askQuestionArray = []
      
      state.avgAskQuestion.forEach(function(date){
        askQuestionArray.push(date.count)
      })
      
      return askQuestionArray;
    },


    averageShowingRequest (state){
      var showingRequestArray = []
      
      state.avgShowingRequest.forEach(function(date){
        showingRequestArray.push(date.count)
      })
      
      return showingRequestArray;
    },

    averageWebsiteOffer (state){
      var websiteOfferArray = []
      
      state.avgWebsiteOffer.forEach(function(date){
        websiteOfferArray.push(date.count)
      })
      
      return websiteOfferArray;
    },

    vendorViewsGroupedByVendor (state) {
      var views = {}

      state.vendorViewsGroupedByVendor.forEach(function(data) {

        if(!(data.vendor in views)) {
          
          let index_name =  (data.vendor === 'BHHS.com') ? 'BerkshireHathawayHS.com' : data.vendor
      
          views[index_name] = {
            total_count: data.count,
            recent_week: data.week,
            recent_week_count: data.count,
            direction: 'up',
            image_url: data.vendor_url,
            change: data.count
          }
        } else {
          views[data.vendor].total_count += data.count;
          if(views[data.vendor].recent_week < data.week) {
            views[data.vendor].recent_week = data.week
            views[data.vendor].change = data.count - views[data.vendor].recent_week_count
            views[data.vendor].direction = (data.count < views[data.vendor].recent_week_count) ? 'down' : 'up'
            views[data.vendor].recent_week_count = data.count
          }
        }
      })
      
      if('Booj' in views){
        delete views.Booj
      }

      const arrayVendor = Object.entries(views);

      const sortArray = arrayVendor.sort((a, b) => {

        if(a[0] == "BerkshireHathawayHS.com") return -1;
        if(b[0] == "BerkshireHathawayHS.com") return 1;

        return b[1].total_count - a[1].total_count;
      });

      let sortedJson = {};
  
      if(Object.keys(views).length > 4) {
        const others = sortArray.slice(4);
        const topElements = sortArray.slice(0, 4);
        sortedJson = Object.fromEntries(topElements);
  
        sortedJson.others = {
          'total_count': others.reduce((acc, curr) => acc + curr[1].total_count, 0),
          'recent_week': '2024-04-21',
          'recent_week_count': others.reduce((acc, curr) => acc + curr[1].recent_week_count, 0),
          'direction': 'down',
          'change': others.reduce((acc, curr) => acc + curr[1].change, 0)
        }
      }else{
        sortedJson = Object.fromEntries(sortArray);
      }
      
      return sortedJson
    },
    vendorInquiriesGroupedByVendor(state) {
      let inqs = {
        first_week: null,
        data: {}
      };
      if (!(state.vendorInquiriesGroupedByVendor && Array.isArray(state.vendorInquiriesGroupedByVendor) && state.vendorInquiriesGroupedByVendor.length > 0))
        return inqs;

      inqs.first_week = state.vendorInquiriesGroupedByVendor[0].week;

      state.vendorInquiriesGroupedByVendor.forEach(item => {
        if (!(item.vendor in inqs.data)) {
          inqs.data[item.vendor] = [];
        }
        inqs.data[item.vendor].push(item)
      })
      return inqs;
    },
    vendorSharesGroupedByVendor (state) {
      let shares = {
        first_week: null,
        data: {}
      };
      if (!(state.vendorSharesGroupedByVendor && Array.isArray(state.vendorSharesGroupedByVendor) && state.vendorSharesGroupedByVendor.length > 0))
        return shares

      shares.first_week = state.vendorSharesGroupedByVendor[0].week;

      state.vendorSharesGroupedByVendor.forEach( item => {
        if(!(item.vendor in shares.data)) {
          shares.data[item.vendor] = [];
        }
        shares.data[item.vendor].push(item)
      })

      return shares;
    },

    refinanceFlag: get('refinanceFlag'),

    vendorShares:         by_vendor_getter("vendorShares"),
    vendorInquiries:      by_vendor_getter("vendorInquiries"),
    vendorViews:          by_vendor_getter("vendorViews"),
    recentShowings:       by_vendor_getter("recentShowings"),
    openHouseData:        by_vendor_getter("openHouseData"),
    openHouseDataSAGE:    get('openHouseDataSAGE'),

    //Website Activities
    savedSearch:          get('savedSearch'),
    savedProperties:      get('savedProperties'),
    sharedProperties:     get('sharedProperties'),
    askQuestion:          get('askQuestion'),
    showingRequest:       get('showingRequest'),
    websiteOffer:         get('websiteOffer'),

    listingPropertyTypes: get('listingPropertyTypes'),
    propertyImage:        get('propertyImage'),
    cityZips:             get('cityZips'),

    listingMarketingTips: get('listingMarketingTips'),
    listingMarketingTip: (state) => (tipTitle) => {
      state.listingMarketingTips.forEach(function(tip) {
        if(tip.title === tipTitle) {
          return tip.description
        }
      });
    },

    /************************

        Home Equity Section

    *************************/

    currentMonthlyRate(state) { // as decimal
      return state.mortgageData.loanInterestRate / 12 / 100
    },
    currentMonthlyPayment(state, {currentMonthlyRate: r, totalNumPayments}) {
      return state.mortgageData.loanInitialValue * r /
              (
                1 - Math.pow( (1 + r), (-totalNumPayments) )
              );
      // This is the old way:
      //       return getters.calculateNewMonthlyPaymentFromNow(getters.numberPaymentsRemaining, getters.currentMonthlyRate)
      // The difference being that the old way calculates based on current remaining principal & today's date,
      // vs the way we do it above which calculates based on original loan value & date.
    },

    totalNumPayments(state) {
      return Math.floor(state.mortgageData.loanLengthInYears) * 12
    },
    numberPaymentsRemaining(state, getters) {
      if(getters.loanRemainingBalance__wasAltered) {
        /*
          This means the mortgagee prepaid something, if the new balance is lower than original
          (or missed payments or something if the balance is higher)

          In either case, we need to recalculate just how many payments would remain at the fixed monthly payment rate
        */
        return getters.calculateNumberOfPayments()
      }
      else{
        return Math.max(getters.totalNumPayments - getters.numPaymentsCompleted, 0);
      }
    },

    numPaymentsCompleted(state) {
      const dateaiSO = moment(state.mortgageData.loanStartDate);
      const startDate = moment(dateaiSO);
      const monthsDiff = moment().diff(startDate, 'months');
      
      // Calcular el número de pagos completos
      let numPayments = Math.floor(monthsDiff);
    
      return Math.max(0, numPayments);
    },

    // total amount remaining from "now" in order to pay off entire loan, including remaining principal and interest
    loanRemainingTotalPayment(_, getters) {
      return getters.currentMonthlyPayment * getters.numberPaymentsRemaining
    },
    loanInterestRemaining(_, getters) {
      return getters.loanRemainingTotalPayment - getters.loanPrincipalRemaining
    },
    loanPrincipalRemaining(_, getters) {
      return getters.loanRemainingBalance // using corelogic API source

      // Alternate way to calculate #1:

      // // p[n] = (d + (1 + r)^n (r s - d))/r
      // const n = getters.currentMonthN;
      // const r = getters.currentMonthlyRate;
      // const s = getters.loanInitialValue;
      // const d = getters.currentMonthlyPayment;
      // return Math.max(0,
      //   (1 / r) * (
      //     d + (
      //       ((r * s) - d) * Math.pow((1 + r), n)
      //     )
      //   )
      // );

      // Alternate way to calculate #2:

      // return getters.loanInitialValue - getters.principalPaidOff_now;
    },

    principalPaidOffByMonthN: (_, getters) => (month_number) => {
      const n = Math.ceil(month_number);

      if(n <= 0) return 0;
      else if(n >= getters.totalNumPayments) return getters.loanInitialValue;
      else {
        const r = getters.loanInterestRate / 12 / 100;
        const bigN = getters.totalNumPayments;
        return (

          getters.loanInitialValue * (
            1 -
            (
              (1 - Math.pow( (1 + r), (n - bigN) ) ) /
              (1 - Math.pow( (1 + r), (0 - bigN) ) )
            )
          )

        )
      }
    },
    totalInterestPaidByMonthN: (_, getters) => (month_number) => {
      let n = Math.floor(month_number);

      if(n < 0) return 0;
      if(n > getters.totalNumPayments) n = getters.totalNumPayments;

      return (getters.currentMonthlyPayment * n) - getters.principalPaidOffByMonthN(n);
    },
    totalInterestPaid_now (_, getters) {
      return getters.totalInterestPaidByMonthN(getters.currentMonthN);
    },
    principalPaidOff_now (_, getters) {
      // Now we are using the user-supplied remaining balance as the source ot truth
      return getters.loanInitialValue - getters.loanRemainingBalance;
    },
    // old value of principalPaidOff_now was calculated based on monthly payment etc:
    principalPaidOff_now__by_calculation(_, getters) {
      return getters.principalPaidOffByMonthN(getters.currentMonthN);
    },

    // Refinance Options component:
    calculateNewMonthlyPaymentFromNow: (_, {loanRemainingBalance}) => (number_of_months, monthly_rate) =>{

        // Calcular el numerador: loanRemainingBalance * monthly_rate * (1 + monthly_rate)^number_of_months
        const numerator = loanRemainingBalance * monthly_rate * Math.pow((1 + monthly_rate), number_of_months);

        // Calcular el denominador: (1 + monthly_rate)^number_of_months - 1
        const denominator = Math.pow((1 + monthly_rate), number_of_months) - 1;

        // Dividir el numerador por el denominador para obtener el pago mensual
        return numerator / denominator;

      },
    
    refinanceByYearTotalSavings: (_, getters) => (refinanceNumYears, refinanceYearlyRateAsDecimal) => {
      const numberOfNewPayments = refinanceNumYears * 12 // 15 -> 180, 30 -> 360
      const newMonthlyRate = refinanceYearlyRateAsDecimal / 12 //15 -> 0.0125, 30 -> 0.025

      const newMonthlyPayment = getters.calculateNewMonthlyPaymentFromNow(numberOfNewPayments, newMonthlyRate)

      const totalPayment = newMonthlyPayment * numberOfNewPayments

      return (getters.loanRemainingTotalPayment - totalPayment)
    },

    refinanceByYearSavings: (_, getters) => (refinanceNumYears, refinanceYearlyRateAsDecimal) => {
     const numberOfNewPayments = refinanceNumYears * 12 // 15 -> 180, 30 -> 360
     const newMonthlyRate = refinanceYearlyRateAsDecimal / 12 //15 -> 0.0125, 30 -> 0.025
     const currentMonthlyPayment = getters.currentMonthlyPayment;
     const newMonthlyPayment = getters.calculateNewMonthlyPaymentFromNow(numberOfNewPayments, newMonthlyRate);
     
      return (currentMonthlyPayment - newMonthlyPayment)
    },

    refinanceRate: state => year => {
      switch(year) {
        case 15:
          return state.refinanceRates.fifteenYearFixed ?? null
        case 30:
          return state.refinanceRates.thirtyYearFixed ?? null
        default:
          console.error(`A refinance rate for an unexpected loan term '${year}' was requested.`)
          return null
      }
    },

    // Pay A Little Extra component:
    calculateNumberOfPayments: (_, {loanRemainingBalance, currentMonthlyRate, currentMonthlyPayment}) =>
      (newPayment = currentMonthlyPayment) => {
        /*
          Formula is n = log(c/(c-rP))/log(1+r)
          n is number of payments remaining to pay off loan at
            P prinicpal (remaining)
            c fixed monthly payment amount
            r *MONTHLY* interest rate (yearly rate / 12)
        */
        const c = newPayment
        const r = currentMonthlyRate
        const P = loanRemainingBalance

        const n = Math.log(c / (c - (r * P))) / Math.log(1 + r)

        return Math.max(n, 0)
    },
    extraPaymentSavings: (_, getters) => (additionalPayment) => {

      const newPayment = getters.currentMonthlyPayment + additionalPayment

      const newNumberOfPayments = getters.calculateNumberOfPayments(newPayment)

      // Using newNumberOfPayments (which may be fractional) as-is SHOULD be fine here
      // because it will take into account how the last payment will be for only a
      // portion of the usual monthly payment, to hit 0 prinicpal without overpaying
      const newTotalPayment = newPayment * newNumberOfPayments

      return getters.loanRemainingTotalPayment - newTotalPayment
    },
    extraPaymentYears: (_, getters) => (additionalPayment) => {

      const newPayment = getters.currentMonthlyPayment + additionalPayment

      const newNumberOfPayments = getters.calculateNumberOfPayments(newPayment)

      // use Math.ceil because a 'fractional payment' still requires a full payment cycle
      return Math.max((getters.numberPaymentsRemaining - Math.ceil(newNumberOfPayments))/12, 0)
    },



    isClaimed: get('isClaimed'),

    // returning the entire struct
    mortgageData: get('mortgageData'),

    // return the frozen boundaries object
    mortgageDataBounds: (state) => mortgageDataBoundsFn(state),

    loanStartDate(state)                  {return state.mortgageData.loanStartDate},
    loanStartDate_pretty(_, getters) {
      return moment(getters.loanStartDate).format("MMMM YYYY");
    },
    loanInitialValue(state)               {return state.mortgageData.loanInitialValue},
    loanLengthInYears(state)              {return state.mortgageData.loanLengthInYears},
    loanInterestRate(state)               {return state.mortgageData.loanInterestRate},
    lastSoldPrice(state)                  {return state.mortgageData.lastSoldPrice},
    loanRemainingBalance(state)           {return state.mortgageData.loanRemainingBalance},
    loanRemainingBalance__original(state) {return state.mortgageData.loanRemainingBalance__original},
    loanRemainingBalance__wasAltered(state, getters) {
      return Math.abs(getters.loanRemainingBalance - getters.loanRemainingBalance__original) >= getters.mortgageDataBounds.loanRemainingBalance.epsilon
    },
    homeValueEstimate(state)
    {
      //special case. Per Joe C, if we have an estimate value from getListing API, use it before netWorth API value
      // if(state.estimate)
      //   return state.estimate;
      return state.mortgageData.homeValueEstimate;
    },
    netWorth(state, getters)
    {
      let remainingBalance = (state.mortgageData.mortgageDetails != null)  ? getters.loanRemainingBalance : 0;
      let value_testing = (state.mortgageData.mortgageDetails != null)  ? getters.homeValueEstimate : state.mortgageData.homeValueEstimate;

      return value_testing - remainingBalance;
    },
    mortgageInfoString(state, getters) {
      if( state.mortgageData.mortgageDetails != null )
        return `${getters.loanLengthInYears} year loan at ${getters.loanInterestRate}% for ${formatPrice(getters.loanInitialValue)} from ${getters.loanStartDate_pretty}`;
      else return null
    },
    mortgageInfo__wasAltered(state, getters) {
      const data = state.mortgageData
      const bounds = getters.mortgageDataBounds

      return (
           Math.abs(data.loanInitialValue     - data.loanInitialValue__original    ) >= bounds.loanInitialValue    .epsilon
        || Math.abs(data.loanLengthInYears    - data.loanLengthInYears__original   ) >= bounds.loanLengthInYears   .epsilon
        || Math.abs(data.loanInterestRate     - data.loanInterestRate__original    ) >= bounds.loanInterestRate    .epsilon
        || Math.abs(data.loanStartDate        - data.loanStartDate__original       ) >= bounds.loanStartDate       .epsilon
        || Math.abs(data.loanRemainingBalance - data.loanRemainingBalance__original) >= bounds.loanRemainingBalance.epsilon
      )
    },

    // Aliases
    originalPrice:  (_, getters) => getters.lastSoldPrice,          // Original value of home when it was last purchased (i.e. for the current mortgage, we assume)
    equity:         (_, getters) => getters.netWorth,               // Calculation: EstimatedValueOfHome - RemainingPrincipalOfLoan
    principal:      (_, getters) => getters.loanPrincipalRemaining, // This is the CURRENT remaining principal, "now", based on today's date vs loan start date.
    currentMonthN:  (_, getters) => getters.numPaymentsCompleted,
    rate:           (_, getters) => getters.refinanceRate,

    isApartment (state) {
      // In lieu of a proper flag to determine if a property is an apartment or not, we are doing our best to compute it here:
      switch(state.property_type) {
        case "Apartment":
        case "Condo":
        case "Condominium":
          return true;
        default:
          return false;
      }
    },
    normalizedPropertyType (state) {
      // Buyside gets all sorts of different values for property types from various vendors.
      // This is an attempt to normalize things, because they don't
      return normalizePropertyType(state.property_type)
    },
    normalizedPropertyTypeForIBuyer(state) {
      let prop_type = "other"
      switch(state.property_type) {
        case "Single Family":
        case "SingleFamily":
          prop_type = "house"
          break

        case "Condo":
        case "Condominium":
          prop_type = "condo"
          break

        case "Townhouse":
        case "Townhouse/Rowhouse":
          prop_type = "townhouse"
          break

        case "Multi Family":
        case "Multi Family Dwelling":
        case "Multi Family 10 Units Less":
        case "MultiFamily2To4":
        case "MultiFamily5Plus":
          prop_type = "multi-family"
          break

        case "Land":
        case "Vacant Land (NEC)":
        case "VacantResidentialLand":
          prop_type = "land"
          break

        default:
          break
      }
      return prop_type
    },
    iBuyer (state, getters) {
      return {
        address1:       state.address.address1,
        address2:       state.address.address2,
        city:           state.address.city,
        state:          state.address.state,
        zipcode:        state.address.zipcode,
        latitude:       state.lat,
        longitude:      state.lng,
        property_type:  getters.normalizedPropertyTypeForIBuyer,
        beds:           state.beds,
        baths:          state.baths,
        baths_full:     state.baths_full,
        baths_half:     state.baths_half,
        finished_area:  state.finished_area,
        lot_size:       state.lot_size,
        last_sold_date: state.last_sold_date,
        stories:        state.stories,
        fireplaces:     state.fireplaces,
        year_built:     state.year_built,
        estimate:       state.estimate
      }
    }
  },
  mutations: {
    _resetState(state) {
      copyIntoRecursive(state, getInitialState())
    },
    setAddress (state, value) {
      state.address.address1 = value.address?.replace(/\+/g, ' ')
      state.address.city = value.city?.replace(/\+/g, ' ') 
      state.address.county = value.county?.replace(/\+/g, ' ')
      state.address.state = value.state?.replace(/\+/g, ' ')
      state.address.zipcode = value.zipcode?.replace(/\+/g, ' ') ?? ''
    },
    setZipcodeData(state, data) {
      state.lat = parseFloat(data.Latitude)
      state.lng = parseFloat(data.Longitude)
    },
    setListing (state, arrayOfListingData) {
      let _this = this

      const mergeAgentEditsIntoListing = function () {
        // Max length of input array should be 2,
        //    one real source and one agent_edit source,
        //    but be careful and presume the worst.
        //    Just take the last instance of each in the array.
        // This fn will mutate the original response but we don't care.

        let realSource, agentEditSource;
        arrayOfListingData.forEach( i => {
          if(i.buyside_details && 'claimed' in i.buyside_details) {
            if(i.buyside_details.claimed) {
              state.isClaimed = true
              // Set the lead_id value in the leads store
              _this.commit('leads/setLeadID', i.buyside_details.lead_id) // We can access the rootStore with _this
              // TODO: See about setting the subscribed value
            }
            state.isSubscribed = i.buyside_details.subscribed
          }
          else if (i.request_lookup_provider && i.request_lookup_provider.source == "AGENT_EDIT")
            agentEditSource = i;
          else realSource = i;
        })

        return copyIntoRecursive(realSource, agentEditSource, false);
      };

      let value;
      if(arrayOfListingData.length == 0) return;
      else if(arrayOfListingData.length == 1)
        value = arrayOfListingData[0];
      else
        value = mergeAgentEditsIntoListing();

      state.address.address1 = value.location.address
      state.address.city = value.location.city
      state.address.county = value.location.county
      state.address.state = value.location.state
      state.address.zipcode = value.location.postal_code
      if(value.location.latitude != undefined)  state.lat = parseFloat(value.location.latitude)
      if(value.location.longitude != undefined) state.lng = parseFloat(value.location.longitude)
      state.beds = Number(value.details.bedrooms)
      state.baths = value.details.bathrooms_total
      state.baths_full = value.details.bathrooms_full
      state.baths_half = value.details.bathrooms_half
      state.finished_area = value.details.finished_area
      state.finished_area_units = value.details.finished_area_units
      state.last_sold_value = value.sold_last_amount
      state.last_sold_date = value.sold_last_date
      state.estimate = value.estimation.estimate_amount
      state.estimate_low = value.estimation.estimate_valuation_low
      state.estimate_high = value.estimation.estimate_valuation_high
      state.property_type = value.details.property_type
      state.year_built = value.details.year_built
      state.lookup_provider_name = value.lookup_provider.name
      state.agentDefinedMAFilters = value.property_search_params
    },
    setAgentDefinedMAFiltersFromSAR(state, value) {
      state.agentDefinedMAFiltersFromSAR = value
    },

    setListingByID (state, value) {
      // Do some initial text massaging for the addresses
      const fullStreetAddress = value.full_street_address.replace(' , ',' #') //only 1 replacement
      const address1 = value.address.address1.replace(/\+/g,' ')

      // Set document title on successful load
      document.title = fullStreetAddress || address1 || document.title

      state.address.address1 = address1
      state.address.city = value.address.city
      state.address.state = value.address.state
      state.address.zipcode = value.address.zipcode
      state.lat = parseFloat(value.lat)
      state.lng = parseFloat(value.lng)
      state.beds = value.beds
      state.baths = value.baths
      state.baths_full = value.baths_full
      state.baths_half = value.baths_half
      state.full_street_address = fullStreetAddress
      state.listing_date = value.listing_date
      state.listing_status = value.listing_status
      state.list_price = value.list_price
      state.lot_size = value.lot_size
      state.living_area = value.living_area
      state.living_area_range = value.living_area_range
      state.property_type = value.property_type
      state.year_built = value.year_built
      if(value.photos.length) {
        state.propertyImage = value.photos[0]
        state.photos = value.photos
      }
      state.active_listing =          value.active_listing
      state.basement =                value.basement
      state.building_floors =         value.building_floors
      state.comments =                value.comments
      state.fireplaces =              value.fireplaces
      state.stories =                 value.stories
      state.township =                value.township
      state.pool =                    value.pool
      state.brokerage_name =          value.brokerage_name
    },
    setListingFromVerifiedAddress (state, verifyAddressObject) {
      const v = verifyAddressObject;

      state.address.address1 =  v.AddressLine1  && v.AddressLine1.trim()  ? v.AddressLine1.trim()           : "";
      state.address.address2 =  v.AddressLine2  && v.AddressLine2.trim()  ? v.AddressLine2.trim()           : "";
      state.address.city =      v.City          && v.City.trim()          ? v.City.trim()                   : "";
      state.address.state =     v.State         && v.State.trim()         ? v.State.trim()                  : "";
      state.address.zipcode =   v.PostalCode    && v.PostalCode.trim()    ? v.PostalCode.trim().slice(0,5)  : "";
      state.altLat =            v.Latitude      && v.Latitude.trim()      ? parseFloat(v.Latitude)          : "";
      state.altLng =            v.Longitude     && v.Longitude.trim()     ? parseFloat(v.Longitude)         : "";
    },
    setListing_consumerEdits (state, newListingData) {
      state.beds                  = newListingData.bedrooms
      state.baths                 = newListingData.bathrooms_total
      state.finished_area         = newListingData.finished_area
      state.finished_area_units   = "sqft"
      state.last_sold_value       = newListingData.sold_last_amount
      state.property_type         = newListingData.property_type
    },

    setListings:                  set('listings'),
    setActiveListings:            set('activeListings'),
    setSoldListings:              set('soldListings'),
    setPendingListings:           set('pendingListings'),
    setMarketActivityPolygonData: set('MarketActivityPolygonData'),
    setListingPropertyTypes:      set('listingPropertyTypes'),
    setShowingFeedbackData:       set('showingFeedbackData'),
    setShowingAgentFeedbackData:  set('showingAgentFeedbackData'),
    setCityZips:                  set('cityZips'),
    setListingMarketingTips:      set('listingMarketingTips'),

    setPolygonEnabledOnMap(state, value) {
      state.polygonEnabledOnMap = !!value //ensure boolean
    },

    setVendorViews(state, value) {
      state.vendorViews = value.total || [];
      state.vendorAvgViews = value.rolling_average || [];
      state.vendorViewsGroupedByVendor = value.vendor || [];
      state.consolidatedViews = value.consolidated_vendor_views || [];
    },
    setVendorInquiries (state, value) {
      state.vendorInquiries = value.total || [];
      state.vendorInquiriesGroupedByVendor = value.vendor || []
      // not using rolling_average ATM
    },
    setVendorShares (state, value) {
      state.vendorShares = value.total || [];
      state.vendorSharesGroupedByVendor = value.vendor || [];
      // not using rolling_average ATM
    },
    setRecentShowings (state, value) {
      state.recentShowings = value || [];
      // not using vendor ATM
      // not using rolling_average ATM
    },
    setOpenHouseData (state, value) {
      state.openHouseData = value.total || [];
      // not using vendor ATM
      // not using rolling_average ATM
    },

    setOpenHouseDataSAGE: set('openHouseDataSAGE'),

    //Website Activites
    setSavedSearch (state, value) {
      if(value == null || value == ''){
        state.savedSearch = 0;
      }else{
        state.savedSearch = value.total.at(-1).count || 0;
        state.avgSavedSearch = value.total || [];
      }
    },
    setSharedProperties (state, value) {
      if(value == null || value == ''){
        state.sharedProperties = 0;
      }else{
        state.sharedProperties = value.total.at(-1).count || 0;
        state.avgSharedProperties = value.total || [];
      }
    },
    setSavedProperties (state, value) {
      if(value == null || value == ''){
        state.savedProperties = 0;
      } else{
        state.savedProperties = value.total.at(-1).count || 0;
        state.avgSavedProperties = value.total || [];
      }
    },
    setAskQuestion (state, value) {
      if(value == null || value == ''){
        state.askQuestion = 0;
      }else{
        state.askQuestion = value.total.at(-1).count || 0;
        state.avgAskQuestion = value.total || [];
      }
    },
    setShowingRequest (state, value) {
      if(value == null || value == ''){
        state.showingRequest = 0;
      }else{
        state.showingRequest = value.total.at(-1).count || 0;
        state.avgShowingRequest = value.total || [];
      }
    },
    setWebsiteOffer(state, value) {
      if(value == null || value == ''){
        state.websiteOffer = 0;
      }else{
        state.websiteOffer = value.total.at(-1).count || 0;
        state.avgWebsiteOffer = value.total || [];
      }
    },

    setLoanStartDate        (state, value) {state.mortgageData.loanStartDate        = normalizeParam(value, 'loanStartDate'       , mortgageDataBoundsFn(state))},
    setLoanLengthInYears    (state, value) {state.mortgageData.loanLengthInYears    = normalizeParam(value, 'loanLengthInYears'   , mortgageDataBoundsFn(state))},
    setLoanInterestRate     (state, value) {state.mortgageData.loanInterestRate     = normalizeParam(value, 'loanInterestRate'    , mortgageDataBoundsFn(state))},
    setLoanRemainingBalance (state, value) {state.mortgageData.loanRemainingBalance = normalizeParam(value, 'loanRemainingBalance', mortgageDataBoundsFn(state))},
    setLoanInitialValue     (state, value) {state.mortgageData.loanInitialValue     = normalizeParam(value, 'loanInitialValue'    , mortgageDataBoundsFn(state))

      // Additionally, handle dependency: loanRemainingBalance can never be larger than loanInitialValue
      if(state.mortgageData.loanRemainingBalance > state.mortgageData.loanInitialValue)
        state.mortgageData.loanRemainingBalance = normalizeParam(value, 'loanRemainingBalance', mortgageDataBoundsFn(state))

    },

    setMortgageData(state, value) {
      // We use the proper setters for these values (which also normalize them)
      //    because these particular fields are also user-editable

      if (value.data.mortgage_details != null) {
        this.commit('listings/setLoanStartDate', new Date(value.data.mortgage_details.start_date));
        this.commit('listings/setLoanInitialValue', Math.round(value.data.mortgage_details.original_balance));
        this.commit('listings/setLoanLengthInYears', Math.round(value.data.mortgage_details.term));
        this.commit('listings/setLoanInterestRate', value.data.mortgage_details.rate);
        this.commit('listings/setLoanRemainingBalance', Math.round(value.data.mortgage_details.remaining_balance)); // This is the remaining amount of principal to pay off on the mortgage "now", not including future interest payments

        // These are copies of the above for making comparisons & to know if the user-editable fields are dirty or clean
        state.mortgageData.loanStartDate__original = normalizeParam(new Date(value.data.mortgage_details.start_date), 'loanStartDate', mortgageDataBoundsFn(state));
        state.mortgageData.loanInitialValue__original = normalizeParam(Math.round(value.data.mortgage_details.original_balance), 'loanInitialValue', mortgageDataBoundsFn(state));
        state.mortgageData.loanLengthInYears__original = normalizeParam(Math.round(value.data.mortgage_details.term), 'loanLengthInYears', mortgageDataBoundsFn(state));
        state.mortgageData.loanInterestRate__original = normalizeParam(value.data.mortgage_details.rate, 'loanInterestRate', mortgageDataBoundsFn(state));
        state.mortgageData.loanRemainingBalance__original = normalizeParam(Math.round(value.data.mortgage_details.remaining_balance), 'loanRemainingBalance', mortgageDataBoundsFn(state));
        state.mortgageData.mortgageDetails = true
      }
      // These are fixed and won't change based on user input
      state.mortgageData.netWorth = value.data.attributes.net_worth;                // This is simply (value.value - value.remaining_balance)
      state.mortgageData.lastSoldPrice = Number(value.data.attributes.last_sold_amount); // ******* CAUTION *******: this may NOT be the same as state.last_sold_value!! This comes from "corelogic" source only, and should be used for My Equity calculations.
      state.mortgageData.homeValueEstimate = value.data.attributes.estimate_value;                    // ******* CAUTION *******: this may NOT be the same as state.estimate!!        This comes from "corelogic" source only, and should be used for My Equity calculations.
    },

    setMyEquityRefinanceRates(state, arrayOfOBRates) {

      const Conforming30YrFixed = arrayOfOBRates.find(x => x.indexName === "Conforming30YrFixed")
      state.refinanceRates.thirtyYearFixed = Conforming30YrFixed ? Conforming30YrFixed.indexValue / 100 : null

      const Conforming15YrFixed = arrayOfOBRates.find(x => x.indexName === "Conforming15YrFixed")
      state.refinanceRates.fifteenYearFixed = Conforming15YrFixed ? Conforming15YrFixed.indexValue / 100 : null

    },

    setIsClaimed: set('isClaimed'),
    setLat:       set('lat'),
    setLng:       set('lng'),
    setAltLat:    set('altLat'),
    setAltLng:    set('altLng'),

    /* Loading Mutations */
    loadingListing:                     set('loadingListing'),
    loadingListings:                    set('loadingListings'),
    loadingListingByID:                 set('loadingListingByID'),
    loadingActiveListings:              set('loadingActiveListings'),
    loadingSoldListings:                set('loadingSoldListings'),
    loadingPendingListings:             set('loadingPendingListings'),
    loadingMarketActivityPolygonData:   set('loadingMarketActivityPolygonData'),
    loadingOpenHouse:                   set('loadingOpenHouse'),
    loadingShowingFeedbackData:         set('loadingShowingFeedbackData'),
    loadingShowingAgentFeedbackData:    set('loadingShowingAgentFeedbackData'),
    loadingRecentShowings:              set('loadingRecentShowings'),
    loadingVendorViews:                 set('loadingVendorViews'),
    loadingVendorInquiries:             set('loadingVendorInquiries'),
    loadingVendorShares:                set('loadingVendorShares'),
    loadingListingMarketingTips:        set('loadingListingMarketingTips'),
    loadingMortgageData:                set('loadingMortgageData'),
    loadingRefinanceRates:              set('loadingRefinanceRates'),

    //Website Activities
    loadingSharedProperties:            set('loadingSharedProperties'),
    loadingSavedSearch:                 set('loadingSavedSearch'),
    loadingSavedProperties:             set('loadingSavedProperties'),
    loadingAskQuestion:                 set('loadingAskQuestion'),
    loadingShowingRequest:              set('loadingShowingRequest'),
    loadingWebsiteOffer:                set('loadingWebsiteOffer'),
  },
  actions: {
    getListing ({ commit, rootState }, obj) {
      commit('loadingListing', true);
      // check for connectionToken
      obj['connectionToken'] = rootState.user.connectionToken
      return api
        .getListing(obj)
        .catch(function(e) {
          commit('loadingListing', false)
          throw e
        })
    },
    getListingByID ({ commit }, listingID = router.currentRoute.params.listingID ) {
      commit('loadingListingByID', true);
      return api.getListingByID(listingID)
        // .finally(()=>{
        //   commit('loadingListingByID', false);
        // })
    },
    getAgentEditsForZipcodeMAR(_, payload) {
      return api.getAgentEditsForZipcodeMAR(payload);
    },
    getListings ({ commit }, params) {
      commit('loadingListings', true);
      if(params.polygon_data) { // presumably only SAGE
        const polygon_data = {...params.polygon_data}
        const new_params = {...params}
        delete new_params.polygon_data
        return api.getListings_polygon(new_params, polygon_data)
      }
      else {
        return api.getListings(params)
      }
    },
    getActiveListings ({ commit }, params) {
      commit('loadingActiveListings', true);
      if(params.polygon_data) { // presumably only SAGE
        const polygon_data = {...params.polygon_data}
        const new_params = {...params}
        delete new_params.polygon_data
        return api.getListings_polygon(new_params, polygon_data, 'active')
      }
      else {
        return api.getListings(params, 'active')
      }
    },
    getSoldListings ({ commit }, params) {
      commit('loadingSoldListings', true);
      if(params.polygon_data) { // presumably only SAGE
        const polygon_data = {...params.polygon_data}
        const new_params = {...params}
        delete new_params.polygon_data
        return api.getListings_polygon(new_params, polygon_data, 'sold')
      }
      else {
        return api.getListings(params, 'sold')
      }
    },
    getPendingListings ({ commit }, params) {
      commit('loadingPendingListings', true);
      if(params.polygon_data) { // presumably only SAGE
        const polygon_data = {...params.polygon_data}
        const new_params = {...params}
        delete new_params.polygon_data
        return api.getListings_polygon(new_params, polygon_data, 'pending')
      }
      else {
        return api.getListings(params, 'pending')
      }
    },
    checkForMARPolygon({commit}, {address, agent_id, report_id}){
      commit('loadingMarketActivityPolygonData', true);
      return api.checkForMARPolygon(address, agent_id,report_id)
    },
    checkForSARPolygon({commit}, {listing_id}){
      commit('loadingMarketActivityPolygonData', true);
      return api.checkForSARPolygon(listing_id)
    },

    getShortCodeForBrokerageID(_, brokerage_id){
      return api.getShortCodeForBrokerageID(brokerage_id)
    },

    getAgentDefinedMAFiltersFromSAR(context, {brokerage_short_name, listing_id}) {
      return api.getAgentDefinedMAFiltersFromSAR(brokerage_short_name, listing_id)
    },

    getListingPropertyTypes ({ commit }) {
      return api
        .getListingPropertyTypes()
        .then(function (propertyTypes) {
          commit('setListingPropertyTypes', propertyTypes)
        })
    },
    getShowingFeedbackData ({ commit }, listingID = router.currentRoute.params.listingID) {
      commit('loadingShowingFeedbackData', true);
      return api
        .getShowingFeedbackData(listingID)
        // .finally(() => {
        //   commit('loadingShowingFeedbackData', false)
        // })
    },
    getShowingAgentFeedbackData ({ commit }, listingID = router.currentRoute.params.listingID) {
      commit('loadingShowingAgentFeedbackData', true);
      return api
        .getShowingAgentFeedbackData(listingID)
        // .finally(() => {
        //   commit('loadingShowingAgentFeedbackData', false)
        // })
    },
    getOpenHouseData ({ commit, state }, listingID = router.currentRoute.params.listingID) {
      commit('loadingOpenHouse', true);
      return api
        .getOpenHouseData(listingID, state.address.zipcode)
        // .finally(() => {
        //   commit('loadingOpenHouse', false);
        // })
    },
    getRecentShowings ({ commit, state }, listingID = router.currentRoute.params.listingID) {
      commit('loadingRecentShowings', true)
      return api
        .getRecentShowings(listingID, state.address.zipcode)
        // .finally(() => {
        //   commit('loadingRecentShowings', false)
        // })
    },
    getVendorInquiries ({ commit, state }, {listingID = router.currentRoute.params.listingID, date_grouping}) {
      commit('loadingVendorInquiries', true)
      return api
        .getVendorInquiries(listingID, date_grouping, state.address.zipcode)
        // .finally(() => {
        //   commit('loadingVendorInquiries', false)
        // })
    },
    getVendorShares({ commit, state }, {listingID = router.currentRoute.params.listingID, date_grouping}) {
      commit('loadingVendorShares', true)
      return api
        .getVendorShares(listingID, date_grouping, state.address.zipcode)
        // .finally(() => {
        //   commit('loadingVendorShares', false)
        // })
    },
    getVendorViews ({ commit, state }, {listingID = router.currentRoute.params.listingID, date_grouping}) {
      commit('loadingVendorViews', true)
      return api
        .getVendorViews(listingID, date_grouping, state.address.zipcode)
        // .finally(() => {
        //   commit('loadingVendorViews', false)
        // })
    },

    //Website Activites

    getSavedSearch({ commit, state }, {listingID = router.currentRoute.params.listingID, date_grouping}) {
      commit('loadingSavedSearch', true)
      return api
        .getListingWebsiteActivitySavedSearch(listingID, date_grouping, state.address.zipcode)
    },

    getSavedProperties({ commit, state }, {listingID = router.currentRoute.params.listingID, date_grouping}) {
      commit('loadingSavedProperties', true)
      return api
        .getListingWebsiteActivitySavedProperties(listingID, date_grouping, state.address.zipcode)
    },

    getSharedProperties({ commit, state }, {listingID = router.currentRoute.params.listingID, date_grouping}) {
      commit('loadingSharedProperties', true)
      return api
        .getListingWebsiteActivitySharedProperties(listingID, date_grouping, state.address.zipcode)
    },

    getAskQuestion({ commit, state }, {listingID = router.currentRoute.params.listingID, date_grouping}) {
      commit('loadingAskQuestion', true)
      return api
        .getListingWebsiteActivityAskQuestion(listingID, date_grouping, state.address.zipcode)
    },

    getShowingRequest({ commit, state }, {listingID = router.currentRoute.params.listingID, date_grouping}) {
      commit('loadingShowingRequest', true)
      return api
        .getListingWebsiteActivityShowingRequest(listingID, date_grouping, state.address.zipcode)
    },

    getWebsiteOffer({ commit, state }, {listingID = router.currentRoute.params.listingID, date_grouping}) {
      commit('loadingWebsiteOffer', true)
      return api
        .getListingWebsiteActivityWebsiteOffer(listingID, date_grouping, state.address.zipcode)
    },
    getCityZips ({ commit }, cityZipsQuery) {
      return api
        .getCityZips(cityZipsQuery)
        .then(function (cityZipsResponse) {
          commit('setCityZips', cityZipsResponse)
        })
    },
    getListingMarketingTips ({ commit }, {listingID = router.currentRoute.params.listingID, ...obj}) {
      commit('loadingListingMarketingTips', true)
      return api
        .getListingMarketingTips(listingID, obj)
        .finally(() => {
          commit('loadingListingMarketingTips', false)
        })
    },
    getMortgageData ({commit, state: moduleState}, {address1, city, state, zipcode, brokerage_id}) {
      commit('loadingMortgageData', true)
      return api.
        getMortgageData({
          address: address1  || moduleState.address.address1,
          city: city        || moduleState.address.city,
          state: state      || moduleState.address.state,
          zipcode: zipcode  || moduleState.address.zipcode,
          brokerage_id: brokerage_id  || moduleState.address.brokerage_id,
        })
        // .then(function (response) {
        //   return response
        //   //commit('setMortgageData', response)
        // })
        .finally(() => {
          commit('loadingMortgageData', false)
        })
    },

    updateListing_consumerEdits({ commit, state, rootState }, newListingData) {
      return api.
        post_editPropertyDetails(
          rootState.leads.leadID,
          state.address,
          newListingData)
        .then(() => {
          // Save the same stuff locally (without a hard refresh)
          commit('setListing_consumerEdits', newListingData)
        })
    },

    getMyEquityRefinanceRates({commit}) {
      commit('loadingRefinanceRates', true)

      return api.getMyEquityRefinanceRates()
        .finally(() => {
          commit('loadingRefinanceRates', false)
        })
    },
    getZipcodeData(context, { address }) {
      if(address.zipcode != '' || address.county != ''){
        return api.getZipcodeLocation(`${address.zipcode}`, `${address.county}`, `${address.state}`)
      }
    }
  }
}
