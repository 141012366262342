import axios from 'axios'
import { fetchFromQueryParam } from '@/util'

var apihost = process.env.VUE_APP_ROOT_API
var apikey = process.env.VUE_APP_APIKEY

// use the page's subdomain to override api endpoint if necessary
let hostname = window.location.hostname
if (hostname.indexOf('staging.') == 0 ||
    hostname.indexOf('stage.') == 0 ||
    hostname.indexOf('stage-') == 0 ||
    hostname.indexOf('ngrok.io') >= 0 ||
    hostname.indexOf('.stage.') > 0 ||
    process.env.VUE_APP_NODE_ENV === "staging") {
  apihost = 'https://staging.api.getbuyside.com/i';
  apikey = 'aVspixXhwQ4WsQ9ubTpji3PzIO0oBa5H2uO2LR4J';
}
if (hostname.indexOf('dev.') == 0 ||
    hostname.indexOf('dev-2.') == 0 ||
    hostname.indexOf('.dev.') > 0 ) {
      apihost = 'https://staging.api.getbuyside.com/i';
      apikey = 'aVspixXhwQ4WsQ9ubTpji3PzIO0oBa5H2uO2LR4J';
}
if (hostname.indexOf('192.168.') >= 0 ||
    hostname.indexOf('ngrok.io') >= 0 ||
    hostname.indexOf('127.0.0.1') >= 0 ||
    hostname.indexOf('localhost') == 0) {
      apihost = 'https://staging.api.getbuyside.com/i';
      apikey = 'aVspixXhwQ4WsQ9ubTpji3PzIO0oBa5H2uO2LR4J';
}
if (apihost == undefined) {
  apihost = 'https://api.getbuyside.com/i';
  apikey = 'szhmqJlOxa3bnyQYH3oLA8gSeXRhrJ6e3RY8DRBb';
}

var api_liberty_net_worth_hostname = process.env.VUE_APP_LIBERTY_NET_WORTH_HOST

// parse subdomain for brokerage token
let brokerage_token = '';

const isSAGE = process.env.VUE_APP_CLIENT == "berkshire";

if(process.env.VUE_APP_NODE_ENV === "development" || process.env.VUE_APP_NODE_ENV === "staging" || (hostname.indexOf('stage-')==0 && hostname.indexOf('.') > 5)) {

  // local always uses a hardcoded brokerage
  if (hostname.indexOf('192.168.') >= 0 ||
      hostname.indexOf('localhost') == 0)
    brokerage_token = 'valuations-foxroach-com'

  // sage.getbuyside.com, dev.sage.getbuyside.com, stage.sage.getbuyside.com
  else if (hostname.indexOf('sage.getbuyside.com') >= 0)
    brokerage_token = 'sage-getbuyside-com'

  else if (hostname.indexOf('stage-')==0 && hostname.indexOf('.') > 5)
    brokerage_token = hostname.slice(6,hostname.indexOf('.'))

  else if(hostname.indexOf('.') >= 0 )
    brokerage_token = hostname.slice(0,hostname.indexOf('.'))

  if(isSAGE) // SAGE should always use this brokerage token no matter what
    brokerage_token = 'sage-getbuyside-com'
}
else if (process.env.VUE_APP_NODE_ENV === "production") {
  brokerage_token = hostname
}

axios.defaults.headers.common['x-api-key'] = apikey;
axios.defaults.headers.common['Content-Type'] = 'application/json';

export default {
  net_worth_host: api_liberty_net_worth_hostname,
  host: apihost,
  brokerage_id: 0,
  brand_id: 0,
  agent_id: 0,
  default_agent_id: 0,

  // Helper function: Parse Current Browser URL to detect potential pass-throughs
  getUrlParameter(name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  },
  //Helper function: detect if we should passthrough vaemail=N to the API for leads POSTS
  vaemailPassthrough() {
    // Testing getUrlParameter...
    let vaemail = this.getUrlParameter('vaemail')

    if(vaemail) {
      if(vaemail.toLowerCase() == 'n') {
        return true
      }
    }
    return false
  },

  /*
    getConfig()
    Gets config and agent/brokerage information for the app
  */
  getConfig (params) {
    var url = this.host + '/config'

    return axios
    .get(url, {
      params: {
        brokerage_domain: fetchFromQueryParam('brokerage_domain') || brokerage_token,
        agent_slug: params.agentId
      }
    }).then( response => {
      if(response.data?.brokerage?.brokerage_id)
        this.brokerage_id = response.data.brokerage.brokerage_id

      if(response.data?.brokerage?.brand_id)
        this.brand_id = response.data.brokerage.brand_id

      //dev hook to help us override subdomain on localhost etc
      if(process.env.VUE_APP_NODE_ENV === "development") {
        let maybeBrokerageID = fetchFromQueryParam('brokerage_id')

        if(!Number.isNaN(Number(maybeBrokerageID)))
          this.brokerage_id = Number(maybeBrokerageID)
      }

      if (response.data?.agent) {
        if(response.data.agent.agentID)
          this.agent_id = response.data.agent.agentID

        if (!response.data.agent.avatar)
          response.data.agent.avatar = '';
        else if (response.data.agent.avatar.substr(0, 4) != 'http')
          response.data.agent.avatar = 'https://devcdn.v1.buyermls.com/uploads/avatars/' + response.data.agent.avatar;
      }
      if(response.data?.brokerage?.default_agent?.agentID) {
        this.default_agent_id = response.data.brokerage.default_agent.agentID
      }
      return response.data
    })
  },

  /*
    getListing()
    Send address data
    Returns property information including estimations
  */
  getListing ({address, connectionToken = null, agentId = null, report_id = null}) {
    const url = this.host + '/properties/details';

    document.title = address.address1.replace(/\+/g,' ') || document.title

    var params = {
      address: address.address1.replace(/\+/g,' '),
      city: address.city,
      state: address.state,
      zip: address.zipcode,
      sources_limit: 1,
      live_sources: true,
      agent_id: agentId || this.agent_id || undefined,
      include_agent_edits: (agentId || this.agent_id) ? 1 : undefined,
    }
    if(connectionToken) {
      params['connection_token'] = connectionToken
    }

    if(report_id){
      params['report_id'] = report_id
    }
    return axios
      .get(url, {params})
      .then( response => response?.data?.length ? response.data : [] )
  },

  /*
    getListingByID()
    Send address data
    Returns property information
  */
  getListingByID (id) {
    const url = this.host + '/listings/' + id + '/brokerage/' + this.brokerage_id

    const params = {
      brand_id: this.brand_id
    };

    return axios
      .get(url, {params})
      .then( response => response.data)
  },

  getAgentEditsForZipcodeMAR({zipcode, report_id = null, agent_id = null, city = null, county = null, state = null}) {
    const url = this.host + '/properties/agent_edits?';

    let url_request = `city=${city}&agent_id=${agent_id}&zipcode=${zipcode}&report_id=${report_id}`;

    if(county != ''){
      url_request = `county=${county}&state=${state}&agent_id=${agent_id}&report_id=${report_id}`;
    }

    return axios
      .get(`${url}${url_request}`)
      .then( response => response.data )
  },

  /*
    getActiveListingRedirect()
    Send address data and brokerage id
    Returns active listing URL if it exists
  */
  getActiveListingRedirect (address) {
    const url = this.host + '/properties/active-listing-url'
    var params = {
      address: address.address1,
      city: address.city,
      state: address.state,
      zipcode: address.zipcode,
      brokerage_id: this.brokerage_id
    }
    return axios
    .get(url, {
      params: params
    }).then(response => response.data)
  },
  getSupplySideTrends (address) {
    var url = this.host + '/supply-side-trends'
    return axios
    .get(url, {
      params: {
        address: address.address1,
        city: address.city,
        state: address.state,
        zip: address.zipcode
      }
    }).then(function (response) {
      if(response.data.length) {
        return response.data
      }
      return []
    })
  },

  /*
    getListings()
    Returns the listings data as an array

    status should only be 'active', 'pending', 'sold', or undefined
  */
  getListings (params, status = undefined) {
    let url = this.host + '/listings/search'
    if(status === 'sold' && !isSAGE)
      url = this.host + '/listings/searchca'

    return axios
    .get(url, {
      params: {...params, status}
    }).then(response => response.data)
  },

  /*
    getListings_polygon()
    Returns the listings data as an array
    These are the specific POST endpoints for getting listings within a geo polygon

    status should only be 'active', 'pending', 'sold', or undefined
  */
  getListings_polygon (params, polygon_data, status = undefined) {
    const url = this.host + '/listings/search/polygon'

    return axios
    .post(url, {polygon: polygon_data}, {
      params: {...params, status}
    }).then(response => response.data)
  },

  getListingPropertyTypes () {
    return axios
    .get(this.host + '/listings/property_types')
    .then( response => response.data.items )
  },

  /*
    getBuyers()
    Returns the featured buyers data as an array
  */
  getBuyers () {
    var url = this.host +'/buyers'
    return axios
    .get(url)
    .then(response => response.data)
  },
  getFeaturedBuyers (address) {
    var url = this.host +'/buyers/featured'
    return axios
    .get(url, {
      params: {
        address: address.address1,
        city: address.city,
        state: address.state,
        zip: address.zipcode,
        brokerage_id: this.brokerage_id,
        live_sources: true,
        testing: 0
      }
    })
    .then(response => response.data)
  },
  /*
    getBuyer(buyerID)
    Returns the featured buyer data as an object
  */
  getBuyer (buyerID) {
    var url = this.host +'/buyers/'+buyerID
    return axios
    .get(url)
    .then(response => response.data)
  },
  /*
    getFunnel()
    Returns the funnel data as an object.
    Keys are `funnel1`, `funnel2`, `funnel3`, `funnel4`
  */
  getFunnel ({ address, brokerage_id, agent_id }) {

    const __local_brokerage_id = brokerage_id || this.brokerage_id
    const __local_agent_id     = agent_id || this.agent_id

    const url = this.host + '/buyers/metrics/funnel'
    return axios
    .get(url, {
      params: {
        address: address.address1,
        city: address.city,
        state: address.state,
        zip: address.zipcode,
        brokerage_id: isSAGE ? 29768 : __local_brokerage_id,
        live_sources: 'False',
        agent_id: __local_agent_id || undefined,
        include_agent_edits: __local_agent_id ? 1 : undefined,
      }
    }).then(response => response.data)
  },
  /*
    getStateFunnel()
    Returns the state funnel data as an object.
  */
  getStateFunnel ({
    latitude,
    longitude,
    from_price,
    to_price,
    bedrooms
  }) {
    const url = this.host + '/buyers/metrics/state_funnel'
    return axios
    .get(url, {
      params: {
        latitude,
        longitude,
        from_price,
        to_price,
        bedrooms,
      }
    }).then(response => response.data)
  },
  /*
    getMarketTrends()
    Returns the market trends data as an array by lat/long
  */
  getMarketTrends({lat, lng, source, address, county, state}) {
    var url = this.host + '/market-trends'
    return axios
    .get(url, {
      params: {
        lat: lat,
        lng: lng,
        zipcode: address ? address.zipcode : undefined,
        county: county ? address.county : undefined,
        state: state ? address.state : undefined,
        source: source || undefined
      }
    })
    .then(response => response.data)
  },

  /*
    getDemographics()
    Returns the demographics data as an object
  */
  getDemographics({lat, lng}) {
    let url = this.host + '/properties/demographics'

    return axios
    .get(url, {
      params: {
        latitude: lat,
        longitude: lng,
        scope: 'all'
      }
    })
    .then(response => response.data)
  },

  /*
    getAvms()
    Returns the AVMs for this property
  */
  getAvms({address, apis = null, agentId = null}) {
    var url = this.host + '/properties/avm'
    return axios
    .get(url, {
      params: {
        address1: address.address1,
        city: address.city,
        state: address.state,
        zipcode: address.zipcode,
        live_sources: true,
        last_valuation: true,
        apis: apis || undefined,
        agent_id: agentId || this.agent_id || undefined,
        include_agent_edits: (agentId || this.agent_id) ? 1 : undefined,
      }
    }).then(response => response.data)
  },
  /*
    getBuyersByPrice()
    Return the number of buyers in price ranges
  */
  getBuyersByPrice(address) {
    var _this = this
    var url = this.host + '/buyers/metrics/price'
    return axios
    .get(url, {
      params: {
        address: address.address1,
        city: address.city,
        state: address.state,
        zip: address.zipcode,
        brokerage_id: isSAGE ? 29768 : _this.brokerage_id,
      }
    }).then(response => response.data)
  },
  /*
    getBuyersByPrice__state()
    Return the number of buyers in price ranges
    This is using the new state-based logic and requires lat/lng instead
  */
  getBuyersByPrice__state({latitude, longitude, county = '', state = '', zipcode = ''}) {
    const url = this.host + '/buyers/metrics/state_price'

    return axios
    .get(url, {
      params: {
        latitude,
        longitude,
        county,
        state,
        zipcode
      }
    }).then(response => response.data)
  },
  /*
    getBuyersByBedroom()
    Return the number of buyers by bedroom preference
  */
  getBuyersByBedroom(address) {
    var _this = this
    var url = this.host + '/buyers/metrics/bedroom'
    return axios
    .get(url, {
      params: {
        address: address.address1,
        city: address.city,
        state: address.state,
        zip: address.zipcode,
        brokerage_id: isSAGE ? 29768 : _this.brokerage_id,
      }
    }).then(response => response.data)
  },
  /*
    getBuyersByBedroom__state()
    Return the number of buyers by bedroom preference
    This is using the new state-based logic and requires lat/lng instead
  */
  getBuyersByBedroom__state({latitude, longitude, county = '', state = '', zipcode = ''}) {
    const url = this.host + '/buyers/metrics/state_bedroom'
    return axios
    .get(url, {
      params: {
        latitude,
        longitude,
        county: county,
        state: state,
        zipcode: zipcode
      }
    }).then(response => response.data)
  },
  /*
    getBuyersByLocation()
    Return the number of buyers per location (city)
  */
  getBuyersByLocation(address) {
    var _this = this
    var url = this.host + '/buyers/metrics/city'

    return axios
    .get(url, {
      params: {
        address: address.address1,
        city: address.city,
        state: address.state,
        county: address.county,
        zip: address.zipcode,
        brokerage_id: isSAGE ? 29768 : _this.brokerage_id,
      }
    }).then(response => response.data)
  },
  /*
    getBuyersMarketDemandGeoJSON()
    Return GeoJSON data for Buyer Heatmap
  */
  getBuyersMarketDemandGeoJSON (address) {
    var _this = this
    return axios
    .get(this.host + '/buyers/heatmap', {
      params: {
        zipcode: address.zipcode,
        brokerage_id: _this.brokerage_id, //96782 Honolulu has a small data repsonse for testing
        brand_id: _this.brand_id
      }
    }).then(response => response.data) //will throw error if failed
  },

  getBuyerSearchAreaGeoJSON (buyerID) {
    return axios
    .get(this.host + '/buyers/' + buyerID + '/geojson')
    .then( response => response.data )
  },

  getCityZips (cityZipsQuery) {
    return axios
    .get(this.host + '/geo/cities/search', {
      params: {
        q: cityZipsQuery
      }
    }).then(function (response) {
      for (var x = 0, len = response.data.length; x < len; x++) {
        response.data[x].cityzipcode = response.data[x].city + ', ' + response.data[x].zipcode
      }
      return response.data
    })
  },
  /*
    getShowingFeedbackData(listingID)
    Return showing feedback data from the customer for seller report page
  */
  getShowingFeedbackData(listingID) {
    var url = this.host + '/listings/' + listingID + '/comments'
    return axios
      .get(url)
      .then(response => response.data)
  },
  /*
    getShowingAgentFeedbackData(listingID)
    Return showing feedback data from the agent for seller report page
  */
  getShowingAgentFeedbackData(listingID) {
    var url = this.host + '/listings/' + listingID + '/agent_comments'
    return axios
      .get(url)
      .then(response => response.data)
  },
  /*
    getOpenHouseData(listingID)
    Return open house metrics for seller report page
  */
  getOpenHouseData(listingID, zip_code = undefined) {
    var url = isSAGE ?
      this.host + '/listings/' + listingID + '/open_houses' :
      this.host + '/listings/' + listingID + '/metrics/open_house_sign_ins/weekly/bsr'

    return axios
      .get(url, {params: {zip_code}})
      .then(response => response.data)
  },
  /*
  getRecentShowings(listingID)
  Return recent showings metrics for seller report page
  */
  getRecentShowings(listingID, zip_code = undefined) {
    var url = isSAGE ?
      this.host + '/listings/' + listingID + '/showings' :
      this.host + '/listings/' + listingID + '/metrics/showings/weekly/bsr'

    return axios
      .get(url, {params: {zip_code}})
      .then(response => response.data)
  },
  /*
  getVendorInquiries(listingID, date_grouping = "week" | "day")
  Return weekly inquiries counts by vendor
  */
  getVendorInquiries(listingID, date_grouping = 'week', zip_code = undefined) {
    var url = isSAGE ?
      this.host + '/listings/' + listingID + '/metric/by_vendor' :
      this.host + '/listings/' + listingID + '/metrics/inquiries/weekly/bsr'

    var params = {
      zip_code
    };

    if(isSAGE) {
      params.date_grouping = date_grouping
      params.metric_type = 'inquiries'
    }else{
      params.brokerage_id = this.brokerage_id
    }

    return axios
      .get(url, {params})
      .then(response => response.data)
  },
  /*
  getVendorShares(listingID, date_grouping = "week" | "day")
  Return weekly shares counts by vendor
  */
  getVendorShares(listingID, date_grouping = 'week', zip_code = undefined) {
    var url = isSAGE ?
      this.host + '/listings/' + listingID + '/metric/by_vendor' :
      this.host + '/listings/' + listingID + '/metrics/shares/weekly/bsr'

    var params = {
      zip_code
    }

    if(isSAGE) {
      params.date_grouping = date_grouping
      params.metric_type = 'shares'
    }else{
      params.brokerage_id = this.brokerage_id
    }

    return axios
      .get(url, {params})
      .then(response => response.data)
  },
  /*
  getVendorViews(listingID, date_grouping = "week" | "day")
  Return weekly view counts
  */
  getVendorViews(listingID, date_grouping = 'week', zip_code = undefined) {
    var url = isSAGE ?
      this.host + '/listings/' + listingID + '/metric/by_vendor' :
      this.host + '/listings/' + listingID + '/metrics/views/daily/bsr'

    var params = {
      zip_code
    };

    if(isSAGE) {
      params.date_grouping = date_grouping
      params.metric_type = 'views'
    }else{
      params.brokerage_id = this.brokerage_id
    }

    return axios
      .get(url, {params})
      .then(response => response.data)
      .catch(error => {
        console.error(error)
        return []
      })
  },
  /*
  getRelatedProperties(args)
  Return Related Properties data (based on report ID)
  */
  getRelatedProperties(args) {
    var url = this.host + '/subscribers/related_properties'
    return axios
    .get(url, {
      params: {
        limit: args.limit ? args.limit : 5,
        offset: args.offset ? args.offset : 0,
        report_id: args.report_id,
      }
    }).then(response => response.data)
  },

  // Get Bloop Bloop Data
  getBloopData(args) {
    var url = this.host + '/buyers/bloop_bloop'
    return axios
    .get(url, {
      params: {
        brokerage_id: this.brokerage_id,
        limit: args.limit,
        offset: args.offset,
        bedroom: args.bedroom,
        house_price: args.house_price
      }
    }).then(response => response.data)
  },

  /*
    getListingMarketingTips
    Return marketing tip information (used on Listing Activity Report page) for this specific address
  */
  getListingMarketingTips(listingID, args) {
    var url = this.host + '/listings/'+listingID+'/marketing_tips'
    return axios
    .get(url, {
      params: {
        sar_template: (args && args.sar_template) || undefined
      }
    })
    .then(response => response.data)
  },

  /*
    getAutocomplete(val)
    Return addresses from input value
  */
  getAutocomplete(val, stateFilter) {
    var url = this.host + '/properties/autocomplete'
    return axios
    .get(url, {
      params: {
        q: val,
        limit: 10,
        states_filter: stateFilter ? stateFilter : undefined
      },
    }).then(function (response) {
      return response.data.Results
    })
  },

  verifyAddress(addressQueryString) {
    let url = this.host + '/properties/verify'
    return axios
    .get(url, {
      params: {
        q: addressQueryString
      }
    }).then( response => {
      if (response.data.Records) {
        return response.data.Records[0]
      }
      return {};
    })
  },

  getZipcodeLocation(zipcode = '', county = '', state = '') {
    let url = this.host + '/geo/zipcodes/location'

    let params = (county != 'undefined' && state != 'undefined') ? {
      county: county,
      state: state
    } : {
      zipcode: zipcode
    }
  
    return axios
    .get(url, {
      params: params
    }).then(response => response.data)
  },

  getMortgageData({ address, city, state, zipcode, brokerage_id }) {
    let url = this.net_worth_host

    return axios
    .get(url, {
      params: {
        address,
        city,
        state,
        zipcode,
        brokerage_id,
        'source': 'attom'
      }
    }).then( response => response.data || {} )
  },

  getPropertyCondition (leadID) {
    let url = this.host + '/leads/' + leadID + "/property_condition"

    return axios
    .get(url)
    .then( response => response.data)
  },

  post_updatePropertyCondition(leadID, conditionValue) {
    let url = this.host + '/leads/' + leadID + "/update_property_condition"
    let params = {}

    if(this.vaemailPassthrough()) {
      params.vaemail = "N"
    }

    let data = {
      'property_condition': conditionValue
    };
    return axios
    .post(url, data, {params})
    .then(response => response.data)
  },

  post_subscribeMonthlyReport(leadID, {firstName, lastName, email}) {
    const url = this.host + `/leads/${leadID}/subscribe_monthly_report`
    let params = {}
    if(this.vaemailPassthrough()) {
      params.vaemail = "N"
    }
    const data = {
      first_name: firstName,
      last_name: lastName,
      email
    }
    return axios
    .post(url, data, {params})
    .then(response => response.data)
  },
  post_unsubscribeMonthlyReport(leadID) {
    const url = this.host + `/leads/${leadID}/unsubscribe_monthly_report`
    return axios
    .post(url)
    .then(response => response.data)
  },

  postMessageToAgentSAGE(data) {
    let url = this.host + '/agents/' + this.agent_id + '/contact'
    let params = {}
    if(this.vaemailPassthrough()) {
      params.vaemail = "N"
    }
    return axios
    .post(url, data, {params})
    .then(response => response.data)
  },

  postMessageToAgent(leadID, data) {
    let url = this.host + '/leads/' + leadID + '/contact'
    let params = {}
    if(this.vaemailPassthrough()) {
      params.vaemail = "N"
    }

    return axios
    .post(url, data, {params})
    .then(response => response.data)
  },

  post_createLead (agentID, {address, firstName, lastName, email, leadTypes}) {
    let url = this.host + '/leads'

    let params = {
      agent_id: agentID || this.agent_id
    }

    if(this.vaemailPassthrough()) {
      params.vaemail = "N"
    }

    let data = {
      address: address || {
          address1: "",
          address2: "",
          city: "",
          state: "",
          zipcode: ""
      },
      first_name: firstName || "",
      last_name: lastName || "",
      email: email || "",
      lead_types: leadTypes || [""] // "Monthly Report|Inquiry|Claim Your Home|Valuation Attempt|iBuyer",
    }

    return axios
    .post(url, data, {params})
    .then(response => response.data)
  },

  put_updateLead (leadID, data) {
    const url = this.host + '/leads/' + leadID

    return axios
    .put(url, data)
    .then(response => response.data)
  },

  getLeadByID (leadID) {
    let url = this.host + '/leads/' + leadID

    return axios
    .get(url)
    .then(response => response.data)
  },

  postIBuyerInfo(leadID, data) {
    let url = this.host + '/leads/' + leadID + '/ibuyer'
    let params = {}
    if(this.vaemailPassthrough()) {
      params.vaemail = "N"
    }
    return axios
    .post(url, data, {params})
    .then(response => response.data);
  },

  postEstimateInfo(leadID, data) {
    let url = this.host + '/leads/' + leadID + '/estimate'
    let params = {}
    if(this.vaemailPassthrough()) {
      params.vaemail = "N"
    }
    let parsedData = { // At this time the endpoint only accepts a value
      "value": data.user_estimate || data.original_estimate
    }
    return axios
    .post(url, parsedData, {params})
    .then(response => response.data);
  },

  /* Authentication API Requests */
  getUserInformation(connectionToken) {
    let url = this.host + '/consumer/'
    let params = {
      connection_token: connectionToken
    }

    return axios
    .get(url, {params})
    .then(response => response.data)
  },

  getUserProperties(connectionToken) {
    let url = this.host + '/consumer/properties'
    let params = {
      connection_token: connectionToken
    }

    return axios
    .get(url, {params})
    .then(response => response.data)
  },

  /* Claim/Unclaim Property Requests */
  claimProperty(leadId, emailAddress, living_area, valuation_estimate, connectionToken) {
    // unclear which fields are currently required...
    let url = this.host + '/consumer/property/claim'
    let params = {
      connection_token: connectionToken
    }
    let data = {
      'lead_id': leadId,
      'sul_email': emailAddress,
      'full_address': '',
      'address1': '',
      'address2': '',
      'city': '',
      'state': '',
      'zipcode': '',
      'beds': '',
      'baths': '',
      'living_area': living_area || 0,
      'valuation_estimate': valuation_estimate || 0,
      'property_type': '',
      'purchase_year': '',
      'sale_transfer_year': ''
    }

    return axios
    .post(url, data, {params})
    .then(response => response.data)
  },

  unclaimProperty(payload, connectionToken) {
    const url = this.host + `/consumer/property/unclaim/`
    let params = {
      connection_token: connectionToken
    }
    return axios
    .post(url, payload, {params})
    .then(response => response.data)
  },

  post_editPropertyDetails(leadId, addressObj, newPropertyDetails) {
    let url = this.host + '/properties/edit'
    let params = {
      address:  addressObj.address1,
      city:     addressObj.city,
      state:    addressObj.state,
      zip:      addressObj.zipcode,
      sources_limit: 1,
      live_sources: true,
    }
    let data = {
      edit_type: "consumer",
      consumer_id: leadId,
      ...newPropertyDetails
    }

    return axios
      .post(url, data, {params})
      .then(response => response.data.added_edit || false)
  },

  post_v1_MyEquityLead(data, shortForm = false) {

    function formatAddress(a) {
      let formatted = ""
      if(a.address1) formatted += a.address1
      if(a.city) formatted += ", " + a.city
      if(a.state) formatted += ", " + a.state
      if(a.zipcode) formatted += a.zipcode

      return formatted
    }

    let userTypeEnum = null
    switch(data.user_type) {
      case 'buyer':
        userTypeEnum = 1; break
      case 'seller':
        userTypeEnum = 2; break
      case 'buyerSeller':
        userTypeEnum = 3; break
      case 'brokerAgent':
        userTypeEnum = 4; break
      case 'owner':
        userTypeEnum = 5; break
    }

    let formdata = new FormData();
    formdata.append("interest", userTypeEnum)

    formdata.append("random_key", window.$cookies.get("random_key"))

    formdata.append("firstname", data.first_name)
    formdata.append("lastname", data.last_name)
    formdata.append("tel", data.phone)
    formdata.append("email", data.from)
    formdata.append("brokerage_id", data.brokerage_id || this.brokerage_id)

    if(data.to_agent_id)
      formdata.append("to_agent_id", data.to_agent_id)
    if(data.to_team_id)
      formdata.append("to_team_id", data.to_team_id)
    if(data.approximate_value)
      formdata.append("approximate_value", data.approximate_value)
    if(data.time_frame)
      formdata.append("time_frame", data.time_frame)
    if(data.agent_name)
      formdata.append("agent_name", data.agent_name)
    if(data.new_equity_form)
      formdata.append("new_equity_form", data.new_equity_form)

    if(!shortForm) {
      formdata.append("address", data.home_address || formatAddress(data.address))

      formdata.append("time_frame", 0)
      formdata.append("message", data.message)
      formdata.append("type", "listing")
      formdata.append("approximate_value", data.property_value)

      formdata.append("Latitude", data.lat)
      formdata.append("Longitude", data.lng)
      formdata.append("bedroom", data.beds)
      formdata.append("bathroom", data.baths)
    }

    return axios.post(
      // This will use the relative path w/ the domain of the parent page that includes this web component
      '/findbuyers/send_message_contact_form',
      formdata)
  },

  getReportOptions(reportID) {
    let url = this.host + '/report_subscriptions/get_report_options'
    let params = {
      report_id: reportID
    }

    return axios
    .get(url, {params})
    .then(response => response.data)
  },

  postBulkSubscribeQueued(payload) {
    const url = this.host + '/report_subscriptions/batch_create_report_subscriptions_queue';

    return axios
    .post(url, payload, {
      headers: {
        'x-api-key': apikey,
        'content-type': 'application/json'
      }
    })
  },

  getBulkSubscribeQueueStatus(bulk_upload_id) {
    const url = this.host + `/report_subscriptions/batch_create_report_status?bulk_upload_id=${bulk_upload_id}`;

    return axios
    .get(url, null, {
      headers: {
        'x-api-key': apikey,
        'content-type': 'application/json'
      }
    })
  },

  checkForMARPolygon(address, agent_id, report_id) {
    const url = this.host + `/report_subscriptions/retrieve_polygon`;

    return axios
    .get(url, {
      params: {
        address,
        agent_id,
        report_id
      },
    })
    .then(response => response?.data?.[0]?.polygon)
    .catch(err => console.error(err))
  },

  checkForSARPolygon(listing_id) {
    const url = this.host + `/report_subscriptions/retrieve_sar_polygon`;

    return axios
    .get(url, {
      params: {
        listing_id,
        source: 'SAGE' // Hard-code this for now
      },
    })
    .then(response => response?.data?.[0]?.polygon)
    .catch(err => console.error(err))
  },

  getShortCodeForBrokerageID(brokerage_id = undefined) {
    const url = this.host + `/brokerages/${brokerage_id || this.brokerage_id}`;

    return axios
    .get(url)
    .then(response => response?.data?.short_name)
    .catch(console.error)
  },

  getAgentDefinedMAFiltersFromSAR(brokerage_short_name, listing_id) {
    const url = this.host + `/listings/get_default_search_values`;

    return axios
    .get(url, {
      params: {
        listing_id,
        source: brokerage_short_name || 'SAGE'
      },
    })
    .then(response => response?.data)
    .catch(console.error)
  },

  getAgentInfo(agentID) {
    const url = this.host + `/agents/${agentID}`

    return axios.get(url)
      .then( response => response.data)
  },

  getAgentsForOffice(officeID) {
    const url = this.host + `/offices/${officeID}/agents`

    return axios.get(url)
      .then( response => response.data )
  },

  getOfficesForAgent(agentID) {
    const url = this.host + `/agents/${agentID}/offices`

    return axios.get(url)
      .then( response => response.data )
  },

  getMyEquityRefinanceRates() {
    const url = this.host + `/finance/rates`

    return axios.get(url)
      .then( response => response.data )
  },

  getListingWebsiteActivitySavedSearch(listingID, date_grouping = 'week', zip_code = undefined, metric_type) {
    var url = this.host + '/listings/saved_searches/' + listingID + '/week/sar';

    var params = {
      brokerage_id: this.brokerage_id,
      zip_code: zip_code
    };

    if(isSAGE) {
      params.date_grouping = date_grouping
      params.metric_type = 'favorite'
    }

    return axios
      .get(url, {params})
      .then(response => response.data)
      .catch(console.error)
  },

  getListingWebsiteActivitySavedProperties(listingID, date_grouping = 'weekly', zip_code = undefined, metric_type) {
    var url = this.host + '/listings/' + listingID + '/metrics/favorite/weekly/sar';

    var params = {
      brokerage_id: this.brokerage_id,
      zip_code: zip_code
    };

    if(isSAGE) {
      params.date_grouping = date_grouping
      params.metric_type = 'favorite'
    }

    return axios
      .get(url, {params})
      .then(response => response.data)
      .catch(console.error)
  },

  getListingWebsiteActivitySharedProperties(listingID, date_grouping = 'weekly', zip_code = undefined, metric_type) {
    var url = this.host + '/listings/' + listingID + '/metrics/share/weekly/sar';

    var params = {
      brokerage_id: this.brokerage_id,
      zip_code: zip_code
    };

    if(isSAGE) {
      params.date_grouping = date_grouping
      params.metric_type = 'share'
    }

    return axios
      .get(url, {params})
      .then(response => response.data)
      .catch(console.error)
  },

  getListingWebsiteActivityAskQuestion(listingID, date_grouping = 'weekly', zip_code = undefined, metric_type) {
    var url = this.host + '/listings/' + listingID + '/metrics/inquiry/weekly/sar';

    var params = {
      brokerage_id: this.brokerage_id,
      zip_code: zip_code
    };

    if(isSAGE) {
      params.date_grouping = date_grouping
      params.metric_type = 'inquiry'
    }

    return axios
      .get(url, {params})
      .then(response => response.data)
      .catch(console.error)
  },

  getListingWebsiteActivityShowingRequest(listingID, date_grouping = 'weekly', zip_code = undefined, metric_type) {
    var url = this.host + '/listings/' + listingID + '/metrics/requestshowing/weekly/sar';

    var params = {
      brokerage_id: this.brokerage_id,
      zip_code: zip_code
    };

    if(isSAGE) {
      params.date_grouping = date_grouping
      params.metric_type = 'requestshowing'
    }

    return axios
      .get(url, {params})
      .then(response => response.data)
      .catch(console.error)
  },

  getListingWebsiteActivityWebsiteOffer(listingID, date_grouping = 'weekly', zip_code = undefined, metric_type) {
    var url = this.host + '/listings/' + listingID + '/metrics/listingoffer/weekly/sar';

    var params = {
      brokerage_id: this.brokerage_id,
      zip_code: zip_code
    };

    if(isSAGE) {
      params.date_grouping = date_grouping
      params.metric_type = 'listingoffer'
    }

    return axios
      .get(url, {params})
      .then(response => response.data)
      .catch(console.error)
  },

  //Growthbook Features Flags
  // Función para obtener el JSON de features
  getFeaturesJson(featuresEndpoint) {
    const url = featuresEndpoint

    return axios.get(url)
      .then(response => response.data)
  },

}
